<template>
  <div class="login-container">
    <el-form
      ref="loginForm"
      :model="loginData"
      :rules="loginRules"
      class="login-form"
      autocomplete="on"
      label-position="left"
      style="width: 500px; padding-top: 32px; margin: 0 auto; background: #fff"
    >
      <div class="title-container">
        <h3 class="title" style="color: black">{{ biaoti }}</h3>
      </div>
      <el-form-item style="background-color: rgb(85 0 0 / 0%); border: 0ch">
        <el-radio v-model="type" @input="genggai" :label="1"
          >账号密码登录</el-radio
        >
        <el-radio v-model="type" @input="genggai" :label="2"
          >手机验证码登录</el-radio
        >
        <el-button type="text" @click.native.prevent="zhuce">注册</el-button>
      </el-form-item>
      <el-form-item prop="username" v-if="type == 1" :error="loginErrMsg">
        <span class="svg-container">
          <svg-icon icon-class="user" />
        </span>
        <el-input
          ref="username"
          v-model="loginData.username"
          @focus="loginErrMsg = ''"
          placeholder="用户名"
          name="username"
          type="text"
          tabindex="1"
          autocomplete="on"
        />
      </el-form-item>
      <!-- <el-form
        :inline="true"
        v-if="type == 2"
        :model="loginData"
        :rules="loginRules"
        class="demo-form-inline"
      > -->
      <el-form-item v-if="type == 2" :error="loginErrMsg">
        <!-- <el-input
            v-model="loginData.phoneNum"
            placeholder="手机号"
          ></el-input> -->
        <span class="svg-container">
          <svg-icon icon-class="user" />
        </span>
        <el-input
          style="width: 300px; height: 50px"
          v-model="loginData.phoneNum"
          @focus="loginErrMsg = ''"
          placeholder="手机号"
          name="phoneNum"
          tabindex="1"
        />

        <el-button
          @click="onSubmit"
          :disabled="buttonDisabled"
          class="login-content-code"
          >{{ buttonName }}</el-button
        >
      </el-form-item>

      <!-- <el-form-item>
          
          <el-button
            @click="onSubmit"
            :disabled="buttonDisabled"
            class="login-content-code"
            >{{ buttonName }}</el-button
          >
        </el-form-item> -->
      <el-form-item v-if="getyanzhengma.code == 1">
        <span class="svg-container">
          <svg-icon icon-class="password" />
        </span>
        <el-input v-model="loginData.code" placeholder="验证码"></el-input>
      </el-form-item>
      <!-- </el-form> -->

      <el-tooltip
        v-if="type == 1"
        v-model="capsTooltip"
        content="大写锁定打开"
        placement="right"
        manual
      >
        <el-form-item v-if="type == 1" prop="password">
          <span class="svg-container">
            <svg-icon icon-class="password" />
          </span>
          <el-input
            :key="passwordType"
            ref="password"
            v-model="loginData.password"
            :type="passwordType"
            placeholder="密码"
            name="password"
            tabindex="2"
            autocomplete="on"
            @keyup.native="checkCapslock"
            @blur="capsTooltip = false"
            @keyup.enter.native="handleLogin"
          />
          <span class="show-pwd" @click="showPwd">
            <svg-icon
              :icon-class="passwordType === 'password' ? 'eye' : 'eye-open'"
            />
          </span>
        </el-form-item>
      </el-tooltip>

      <el-button
        :loading="loading"
        type="primary"
        style="width: 100%; margin-bottom: 30px"
        @click.native.prevent="handleLogin"
        >登录</el-button
      >
      <!-- <el-form-item
        label=""
        size="normal"
        style="background-color: rgb(85 0 0 / 0%); border: 0ch"
      >
        <h3>技术支持：青岛盈新数科信息技术有限公司</h3>
      </el-form-item> -->
    </el-form>
    <div
      style="
        position: absolute;
        bottom: 0px;
        left: 40%;
        display: flex;
        align-items: center;
        justify-content: center;
      "
    >
      <h5 style="color: azure !important">
        技术支持：青岛盈新数科信息技术有限公司
      </h5>
    </div>

    <!-- <el-row>
      <el-col :span="8"><div class="grid-content bg-purple">1</div></el-col>
      <el-col :span="8"
        ><div class="grid-content bg-purple-light">
          <h3>技术支持：青岛盈新数科信息技术有限公司</h3>
        </div></el-col
      >
      <el-col :span="8"><div class="grid-content bg-purple">3</div></el-col>
    </el-row> -->
    <!-- <el-container>
      <el-header>Header</el-header>
      <el-main>Main</el-main>
      <el-footer>
        <div class="login-container">
          <div class="title-container">
            <h3>技术支持：青岛盈新数科信息技术有限公司</h3>
          </div>
        </div>
      </el-footer>
    </el-container> -->
    <edits ref="edit" />
  </div>
</template>

<script>
import { setToken } from "@/common/utils/auth";
import util from "@/common/utils/util.js";
import { login, checkcode, phonelogin } from "@/api/login";
import Edits from "./model/edit.vue";

export default {
  name: "login",
  components: { Edits },

  data() {
    return {
      type: 1,
      biaoti: "青岛影视周线上平台服务系统登录",
      buttonName: "获取验证码", //按钮名称
      buttonDisabled: false, //按钮是否禁用
      time: 60, //时间
      succ: "", //是否成功发送，根据这个条件来判断是否进入定时器
      loginErrMsg: "",
      loginData: {
        username: "",
        password: "",
        code: "",
        phoneNum: "",
      },
      getyanzhengma: {},
      loginRules: {
        username: [
          { required: true, message: "请输入用户名", trigger: "blur" },
        ],
        phone: [{ required: true, message: "请输入手机号", trigger: "blur" }],
        password: [
          {
            required: true,
            min: 6,
            message: "密码不能少于6个字符",
            trigger: "blur",
          },
        ],
      },
      passwordType: "password",
      capsTooltip: false,
      loading: false,
      showDialog: false,
      redirect: undefined,
      otherQuery: {},
    };
  },
  mounted() {
    if (util.isLogin()) {
      this.$router.replace("/index");
    }
  },
  methods: {
    genggai() {
      console.log(this.type);
      if (this.type == 1) {
        this.getyanzhengma = {};
        this.biaoti = "青岛影视周系统密码登录";
      }
      if (this.type == 2) {
        this.biaoti = "青岛影视周系统手机号登录/注册";
      }
    },

    zhuce() {
      this.$refs["edit"].showEdit();
    },
    showPwd() {
      if (this.passwordType === "password") {
        this.passwordType = "";
      } else {
        this.passwordType = "password";
      }
      this.$nextTick(() => {
        this.$refs.password.focus();
      });
    },
    onSubmit() {
      this.$refs.loginForm.validate((valid) => {
        if (valid) {
          checkcode({ phone: this.loginData.phoneNum })
            .then((res) => {
              console.log(res.code, 12321321);
              if (res.code == 1) {
                this.succ = "发送成功";
                this.getyanzhengma = res;
                this.$alert(res.msg, {
                  dangerouslyUseHTMLString: true,
                  type: "success",
                });
                var countDown = setInterval(() => {
                  if (this.time < 1) {
                    this.buttonDisabled = false;
                    this.buttonName = "获取验证码";
                    this.time = 60;
                    clearInterval(countDown);
                  } else if (this.time >= 0 && this.succ == "发送成功") {
                    this.buttonDisabled = true;
                    this.buttonName = this.time-- + "s后重发";
                  }
                }, 1000);
              } else {
                this.$alert(res.msg, {
                  dangerouslyUseHTMLString: true,
                  type: "warning",
                });
                this.succ = "发送失败";
              }
            })
            .catch((error) => {
              console.log(error);
            });
        } else {
          return false;
        }
      });
    },
    handleLogin() {
      this.$refs.loginForm.validate((valid) => {
        if (valid) {
          this.loginErrMsg = "";
          this.loading = true;
          let func = login;
          if (this.type == 2) {
            func = phonelogin;
          }
          func(this.loginData)
            .then((res) => {
              if (res.code == 0) {
                localStorage.setItem("res", JSON.stringify(res));
                this.$alert(res.msg, {
                  dangerouslyUseHTMLString: true,
                  type: "warning",
                });
                this.loginErrMsg = res.msg;
              } else if (res.code == 1) {
                let data = res.data;
                console.log(data, "23111111");
                setToken(data.token);
                localStorage.setItem("ref", JSON.stringify(data));
                util.setCookie("uname", data.uname);
                util.setCookie("cname", data.cname);
                util.setCookie("rname", data.rname);
                util.setCookie("yyCompanyName", data.yyCompanyName);
                this.$router.replace("/index");
                this.loading == true ? (abc = 1) : (abc = 2);
              }
              // console.log(res.code, 555555555555555);

              this.loading = false;
            })
            .catch((res) => {
              this.loading = false;
            });
        } else {
          return false;
        }
      });
    },
    checkCapslock(e) {
      const { key } = e;
      this.capsTooltip = key && key.length === 1 && key >= "A" && key <= "Z";
    },
  },
};
</script>



<style lang="scss" >
$bg: #283443;
$light_gray: #fff;
$cursor: #fff;

@supports (-webkit-mask: none) and (not (cater-color: $cursor)) {
  .login-container .el-input input {
    color: $cursor;
  }
}

/* reset element-ui css */
.login-container {
  width: 100%;
  text-align: center;
  font-size: 32px;
  font-weight: 600;
  height: 32px;
  line-height: 32px;
  color: rgba(0, 0, 0, 0.85);
  .el-input {
    display: inline-block;
    height: 47px;
    width: 85%;

    input {
      background: transparent;
      border: 0px;
      -webkit-appearance: none;
      border-radius: 0px;
      padding: 12px 5px 12px 15px;
      color: $light_gray;
      height: 47px;
      caret-color: $cursor;
      background-repeat background-position &:-webkit-autofill {
        box-shadow: 0 0 0px 1000px #f1f2f2 inset !important;
        -webkit-text-fill-color: $cursor !important;
      }
    }
  }

  .el-form-item {
    border: 1px solid rgba(255, 255, 255, 0.1);
    background: rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    color: #454545;
  }
}
</style>

<style lang="scss" scoped>
$bg: #2d3a4b;
$dark_gray: #889aa4;
$light_gray: #eee;

.login-container {
  min-height: 100%;
  width: 100%;
  background-color: $bg;
  overflow: hidden;
  background: url("../images/login.jpg") center center fixed no-repeat;
  background-size: cover;
  .login-form {
    position: relative;
    width: 450px;
    max-width: 100%;
    padding: 160px 35px 0;
    margin: 0 auto;
    overflow: hidden;
  }

  .tips {
    font-size: 14px;
    color: #fff;
    margin-bottom: 10px;

    span {
      &:first-of-type {
        margin-right: 16px;
      }
    }
  }

  .svg-container {
    padding: 6px 0px 6px 15px;
    color: $dark_gray;
  }

  .title-container {
    position: relative;

    .title {
      font-size: 26px;
      color: $light_gray;
      margin: 0px auto 40px auto;
      text-align: center;
      font-weight: bold;
    }
  }

  .show-pwd {
    position: absolute;
    right: 10px;
    top: 7px;
    font-size: 16px;
    color: $dark_gray;
    cursor: pointer;
    user-select: none;
  }
}
</style>
