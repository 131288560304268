import request from "@/common/utils/request";
import Vue from "vue";

export function login(data) {
  return request({
    url: Vue.prototype.$adminApiUrl + "/login",
    method: "post",
    data
  });
}
export function checkcode(params) {
  return request({
    url: Vue.prototype.$adminApiUrl + "/check-code",
    method: "get",
    params
  });
}
export function phonelogin(data) {
  return request({
    url: Vue.prototype.$adminApiUrl + "/user/phone-login",
    method: "post",
    data
  });
}
export function getRegistrationCode(params) {
  return request({
    url: Vue.prototype.$adminApiUrl + "/user/getRegistrationCode",
    method: "get",
    params
  });
}
