<template>
  <div>
    <!-- <el-row>
      <el-col :span="24">
        <div class="search-box">
          <el-form :inline="true" size="medium">
            <el-form-item>
              <el-button type="primary" size="small" @click="showAddDialogForm"
                >新增用户</el-button
              >
            </el-form-item>
          </el-form>

          <el-form :inline="true" size="medium" :model="searchForm">
            <el-form-item label="用户名">
              <el-input
                v-model="searchForm.username"
                size="small"
                placeholder="用户名"
              ></el-input>
            </el-form-item>
            <el-form-item label="姓名">
              <el-input
                v-model="searchForm.nickname"
                size="small"
                placeholder="姓名"
              ></el-input>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" size="small" @click="search"
                >查询</el-button
              >
            </el-form-item>
          </el-form>
        </div>
      </el-col>
    </el-row> -->

    <el-dialog
      title="新增用户"
      :visible.sync="editFormVisible"
      width="500px"
      :close-on-click-modal="false"
      @close="close"
    >
      <el-form :model="editForm" ref="editForm" :rules="editRules">
        <el-form-item label="姓名" label-width="100px" prop="nickname">
          <el-input
            v-model="editForm.nickname"
            size="small"
            style="color: black"
            autocomplete="off"
          ></el-input>
        </el-form-item>
        <el-form-item label="用户名" label-width="100px" prop="username">
          <el-input
            v-model="editForm.username"
            size="small"
            autocomplete="off"
          ></el-input>
        </el-form-item>
        <el-form-item label="密码" label-width="100px" prop="password">
          <el-input
            v-model="editForm.password"
            size="small"
            autocomplete="off"
            show-password
          ></el-input>
        </el-form-item>
        <el-form-item label="电话号码" label-width="100px" prop="phone">
          <el-input
            style="width: 245px"
            v-model="editForm.phone"
            size="small"
            autocomplete="off"
            placeholder="手机号"
            name="phoneNum"
            tabindex="1"
          ></el-input>
          <el-button
            @click="onSubmit"
            :disabled="buttonDisabled"
            class="login-content-code"
            >{{ buttonName }}</el-button
          >
        </el-form-item>
        <el-form-item
          label-width="100px"
          label="验证码"
          v-if="getyanzhengma.code == 1"
        >
          <el-input v-model="editForm.code" placeholder="验证码"></el-input>
        </el-form-item>
        <el-form-item label="角色" label-width="100px" prop="role">
          <el-select v-model="editForm.role" placeholder="请选择角色">
            <el-option
              v-for="item in roleList"
              :key="item.id"
              :label="item.roleName"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          label="邀约单位"
          label-width="100px"
          prop="yyCompanyId"
          v-if="editForm.role == 2 || editForm.role == 5 || editForm.role == 10"
        >
          <el-select
            v-model="editForm.yyCompanyId"
            placeholder="请选择邀约单位"
          >
            <el-option
              v-for="item in yyCompanyList"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>

        <!-- <el-form-item
          label="锁定状态"
          size="small"
          label-width="100px"
          prop="phoneNum"
        >
          <el-checkbox v-model="editForm.locked" @change="suoding"
            >锁定</el-checkbox
          >
        </el-form-item> -->
        <el-form-item label="备注" size="small" label-width="100px">
          <el-input v-model="editForm.note" autocomplete="off"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="editFormVisible = false">取 消</el-button>
        <el-button
          type="primary"
          @click.native.prevent="submitForm"
          :loading="loading"
          >{{ editForm.id == 0 ? "添 加" : "修 改" }}</el-button
        >
      </div>
    </el-dialog>
    <!-- <chakan ref="chakan" @fetch-data="getDataList(currentPage)" /> -->
  </div>
</template>
  
  <script>
import { validPhone } from "@/common/utils/validate.js";
import request from "@/common/utils/request.js";
import util from "@/common/utils/util.js";
import {
  getUserList,
  userRegistration,
  updateUser,
  deleteUser,
} from "@/api/user";
import { getAllRole } from "@/api/role";
import { getYycompanyList } from "@/api/yy_company";
import { getFrontUserList } from "@/api/front_user";
// import chakan from "../model/chakan.vue";
import { login, getRegistrationCode, phonelogin } from "@/api/login";

export default {
  name: "userManage",
  // components: { chakan },

  data() {
    return {
      total: 0,
      buttonName: "获取验证码", //按钮名称
      buttonDisabled: false, //按钮是否禁用
      time: 60, //时间

      pageSize: 10,
      currentPage: 1,
      tableData: [],
      tableHeight: 200,
      editFormVisible: false,
      loading: false,
      getyanzhengma: {},

      editForm: {
        id: 0,
        nickname: "",
        username: "",
        password: "",
        locked: "",
        note: "",
        role: "",
        yyCompanyId: "",
        phone: "",
        frontUserId: "",
      },
      editRules: {
        nickname: [{ required: true, message: "请输入姓名", trigger: "blur" }],
        username: [
          { required: true, message: "请输入用户名", trigger: "blur" },
        ],
        password: [
          {
            required: true,
            min: 6,
            message: "密码不能少于6个字符",
            trigger: "blur",
          },
        ],
        role: [{ required: true, message: "请选择角色", trigger: "blur" }],
        phone: [
          {
            validator: validPhone,
            message: "手机号码格式错误",
            trigger: "blur",
          },
        ],
      },
      loadingDiv: null,
      searchForm: {
        username: "",
        nickname: "",
      },
      roleList: [
        {
          activate: null,
          createTime: null,
          createUserId: null,
          deleted: null,
          id: 5,
          roleName: "邀约人员",
          updateTime: null,
          updateUserId: null,
        },
      ],
      yyCompanyList: [],
      frontUserList: [],
    };
  },
  watch: {
    "editForm.id": function (val) {
      if (this.editForm.id == 0) {
        this.editRules["password"] = [
          {
            required: true,
            min: 6,
            message: "密码不能少于6个字符",
            trigger: "blur",
          },
        ];
      } else {
        this.editRules["password"] = [
          {
            required: false,
            min: 6,
            message: "密码不能少于6个字符",
            trigger: "blur",
          },
        ];
      }
    },
  },
  mounted() {
    // this.getDataList(this.currentPage);

    this.$nextTick(() => {
      this.tableHeight = document.documentElement.clientHeight - 198;
    });
    window.onresize = () => {
      this.$nextTick(() => {
        this.tableHeight = document.documentElement.clientHeight - 198;
      });
    };
  },
  methods: {
    suoding() {
      console.log(this.editForm.locked);
    },
    close() {
      console.log(123);
      this.editFormVisible = false;
      this.editForm = {};
    },
    handchakan(row) {
      this.$refs["chakan"].showEdit(row);
    },
    onSubmit() {
      getRegistrationCode({ phone: this.editForm.phone })
        .then((res) => {
          console.log(res.code, 12321321);
          if (res.code == 1) {
            this.succ = "发送成功";
            this.getyanzhengma = res;
            this.$alert(res.msg, {
              dangerouslyUseHTMLString: true,
              type: "success",
            });
            var countDown = setInterval(() => {
              if (this.time < 1) {
                this.buttonDisabled = false;
                this.buttonName = "获取验证码";
                this.time = 60;
                clearInterval(countDown);
              } else if (this.time >= 0 && this.succ == "发送成功") {
                this.buttonDisabled = true;
                this.buttonName = this.time-- + "s后重发";
              }
            }, 1000);
          } else {
            this.$alert(res.msg, {
              dangerouslyUseHTMLString: true,
              type: "warning",
            });
            this.succ = "发送失败";
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    showEdit() {
      this.editFormVisible = true;
      this.init();
      // this.getAllRole();
    },
    init() {
      getYycompanyList().then((res) => {
        if (res.code) {
          this.yyCompanyList = res.data;
        }
      });
    },
    getAllRole() {
      getAllRole().then((res) => {
        console.log(res);
        if (res.code == 0) {
          this.$alert("获取角色列表失败", "", {
            dangerouslyUseHTMLString: true,
            type: "warning",
          }).then(() => {
            this.editFormVisible = false;
          });
        } else if (res.code == 1) {
          this.roleList = res.data;
          console.log(res, "2111111");
        }
      });
    },
    showAddDialogForm() {
      //if (this.frontUserList && this.frontUserList.length <= 0){
      getFrontUserList().then((res) => {
        if (res.code) {
          this.frontUserList = res.data;
        }
      });
      //}

      if (this.$refs.editForm) {
        this.$refs.editForm.clearValidate();
      }
      this.editForm.id = 0;
      this.editForm.nickname = "";
      this.editForm.username = "";
      this.editForm.note = "";
      this.editForm.password = "";
      this.editForm.locked = 0;
      this.editForm.phone = "";
      this.editFormVisible = true;
      this.roleList = [];
      this.editForm.frontUserId = "";
      // this.getAllRole();
    },
    handleEdit(index, row) {
      //if (this.frontUserList && this.frontUserList.length <= 0){
      getFrontUserList().then((res) => {
        if (res.code) {
          this.frontUserList = res.data;
        }
      });
      //}

      // this.getAllRole();
      if (this.$refs.editForm) {
        this.$refs.editForm.clearValidate();
      }
      this.editForm.id = row.id;
      this.editForm.nickname = row.nickname;
      this.editForm.username = row.username;
      if (row.roleId <= 0) {
        this.editForm.role = "";
      } else {
        this.editForm.role = row.roleId;
      }
      this.editForm.note = row.note;

      if (row.locked) {
        this.editForm.locked = true;
      } else {
        this.editForm.locked = false;
      }
      this.editForm.password = "";
      this.editForm.yyCompanyId = row.yyCompanyId;
      this.editForm.phone = row.phone;
      this.editForm.frontUserId = row.frontUserId;

      this.editFormVisible = true;
    },
    submitForm() {
      // 2 邀约单位 5 邀约人员
      if (
        this.editForm.role == 2 ||
        this.editForm.role == 5 ||
        this.editForm.role == 10
      ) {
        if (util.isEmpty(this.editForm.yyCompanyId)) {
          this.$alert("请选择邀约单位");
          return;
        }
      } else {
        this.editForm.yyCompanyId = "";
      }

      this.$refs.editForm.validate((valid) => {
        if (valid) {
          this.loading = true;
          let loadingText = "添加中...";
          if (this.editForm.id) {
            loadingText = "修改中...";
          }
          const loadingDiv = util.showLoading(loadingText);
          let func = userRegistration;
          // if (this.editForm.id == 0) {
          //   func = userRegistration;
          // }
          func(this.editForm)
            .then((res) => {
              let title = "添加";

              if (res.code == 0) {
                this.$alert(res.msg, title + "失败", {
                  dangerouslyUseHTMLString: true,
                  type: "warning",
                });
              } else if (res.code == 1) {
                this.$alert(res.msg, title + "成功", {
                  dangerouslyUseHTMLString: true,
                  type: "success",
                }).then(() => {
                  this.editFormVisible = false;
                });
              }
              this.loading = false;
              loadingDiv.close();
            })
            .catch((res) => {
              this.loading = false;
              loadingDiv.close();
            });
        }
      });
    },
    handleDelete(index, row) {
      let loadingDiv;
      this.$confirm(
        "是否确认删除用户:" + row.nickname + "(用户名:" + row.nickname + ")",
        "删除确认"
      )
        .then((res) => {
          if ((res = "confirm")) {
            loadingDiv = util.showLoading("删除中...");
            return deleteUser({ id: row.id });
          }
        })
        .then((res) => {
          loadingDiv.close();
          if (res.code == 0) {
            this.$alert(res.msg, "删除失败", {
              dangerouslyUseHTMLString: true,
              type: "warning",
            });
          } else if (res.code == 1) {
            this.$alert(res.msg, "删除成功", {
              dangerouslyUseHTMLString: true,
              type: "success",
            }).then((res) => {
              this.editFormVisible = false;
              this.getDataList(this.currentPage);
            });
          }
        })
        .catch((res) => {
          if (loadingDiv) {
            loadingDiv.close();
          }
        });
    },

    getDataList(pageNum) {
      const loadingDiv = util.showLoading();

      getUserList({
        username: this.searchForm.username,
        nickname: this.searchForm.nickname,
        pageSize: this.pageSize,
        pageNum: pageNum,
      })
        .then((res) => {
          if (res.code == 0) {
            this.$message({
              showClose: true,
              message: res.msg,
              type: "error",
            });
          } else if (res.code == 1) {
            let data = res.data;
            this.currentPage = pageNum;
            this.total = data.total;
            this.tableData = data.dataList;
          }
          loadingDiv.close();
        })
        .catch((res) => {
          loadingDiv.close();
        });
    },
    search() {
      this.getDataList(1);
    },
    handleCurrentChange(pageNum) {
      this.getDataList(pageNum);
    },
  },
};
</script>
  
  <style scoped lang="scss">
// @import "~@/common/styles/element-variables.scss";
.search-box {
  background-color: #ffffff;
  min-height: 36px;
  line-height: 36px;
  padding-top: 2px;
  padding-bottom: 6px;
  .el-form-item {
    margin-bottom: 0px !important;
  }
  .el-form--inline {
    display: inline-block;
  }
}

input::-webkit-input-placeholder,
textarea::-webkit-input-placeholder {
  color: #666 !important;
  font-size: 16px !important;
}

input:-moz-placeholder,
textarea:-moz-placeholder {
  color: #666 !important;
  font-size: 16px !important;
}

input::-moz-placeholder,
textarea::-moz-placeholder {
  color: #666 !important;
  font-size: 16px !important;
}

input:-ms-input-placeholder,
textarea:-ms-input-placeholder {
  color: #666 !important;
  font-size: 16px !important;
}
</style>
  <style lang="scss">
.el-dialog__header {
}

.el-dialog__body {
  padding-top: 10px;
  padding-bottom: 0px;
}
.el-dialog__footer {
  padding-top: 0px;
}
.login-container .el-input input {
  background: transparent;
  border: 0px;
  -webkit-appearance: none;
  border-radius: 0px;
  padding: 12px 5px 12px 15px;
  color: black !important;
  /* height: 47px; */
  caret-color: #fff;
}
</style>
  